import React from 'react'
import styles from './pageLayout.module.scss'
import { Footer } from '../../footer/footer'
import { Helmet } from 'react-helmet'
import { VerticalLayout } from '../verticalLayout/verticalLayout'

export const PageLayout = ({ children, title, description }) => {
  return (
    <div className={styles.pageContainer}>
      <Helmet htmlAttributes={{ lang: `en` }}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="keywords" cpntent="elia,maino,software,blog,coding" />
        <meta name="description" content={description}></meta>
      </Helmet>
      <VerticalLayout className={styles.mainContainer}>
        <main>{children}</main>
        <Footer />
      </VerticalLayout>
    </div>
  )
}
