import React, { useState, useEffect } from 'react'

import { PageLayout } from '../components/layout/pageLayout/pageLayout'
import { ActionButton } from '../components/buttons/actionButton/actionButton'
import { VerticalLayout } from '../components/layout/verticalLayout/verticalLayout'

const ITEMS = {
  'Marius': 3.5,
  'Capitan Special': 6,
  'Pastis': 4,
  'Demi': 4,
  'Pinte': 5.5,
}

export default () => {
  const [valueToConvert, setValueToConvert] = useState(0)
  const [selectedItem, setSelectedItem] = useState('Marius')
  const [convertResult, setConvertResult] = useState(null)

  const onValueToConvertChange = (e) => {
    setValueToConvert(parseInt(e.target.value))
  }

  const onItemChange = (e) => {
    setSelectedItem(e.target.value)
  }

  const convert = () => {
    const selectedItemPrice = ITEMS[selectedItem]
    const numberOfBuyableItems = parseInt(valueToConvert / selectedItemPrice)

    setConvertResult(`With ${valueToConvert} euros you can buy ${numberOfBuyableItems} ${selectedItem} chez Camille`);
  }

  useEffect(() => {
    document.documentElement.setAttribute('data-user-color-scheme', 'camille')
  })

  return (
    <PageLayout
      title="The most amazing converter in the world"
      description="The most amazing converter in the world. Convert anything you want to anything you want."
    >
      <VerticalLayout>
        <h1>The most amazing converter in the world.</h1>
        <h2>Convert anything you want to anything you can buy chez Camille.</h2>
        <div>
          <label htmlFor="price-to-convert">Price to convert (currency is euro): </label>
          <input value={valueToConvert} onChange={onValueToConvertChange} id="price-to-convert" type="number" min="0" step="any" />
        </div>
        <div>
          <label htmlFor="items-list">To: </label>
          <select id="items-list" value={selectedItem} onChange={onItemChange}>
            <option value="Marius">Marius</option>
            <option value="Capitan Special">Capitan Special</option>
            <option value="Pastis">Pastis</option>
            <option value="Demi">Demi</option>
            <option value="Pinte">Pinte</option>
          </select>
        </div>
        <ActionButton onClick={convert}>Convert</ActionButton>
        {
          convertResult ? <p>{convertResult}</p> : null
        }
        <img alt="camille logo" src="camille.jpg" style={{ width: `100%`}}/>
      </VerticalLayout>
    </PageLayout>
  )
}
